<template>
  <b-modal
    id="modalChangePsn"
    :title="title"
    noCloseOnBackdrop
    noCloseOnEsc
    @show="onShow"
    centered
    sm
  >
    <b-form @submit.prevent="onSubmit" class="row">
      <div class="form-group col-12 col-md-6">
        <label for="nick">{{ nick }}</label>
        <b-form-input
          name="nick"
          id="nick"
          v-model="form.nick"
          v-validate="'required|max:30'"
          :state="validateState('nick')"
        />
        <span class="error-inputs">{{ errors.first("nick") }}</span>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="price">{{ $t("price") }}</label>
          <b-input-group>
            <b-input-group-text slot="prepend"
              ><i class="fa fa-usd px-3"></i
            ></b-input-group-text>
            <b-form-input
              type="text"
              name="price"
              id="price"
              readonly
              v-validate="'required'"
              :state="validateState('price')"
              v-model="form.price"
            ></b-form-input>
          </b-input-group>
          <span class="error-inputs">{{ errors.first("price") }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="number">{{ $t("cardNumber") }}</label>
          <b-input-group size="lg">
            <b-input-group-text slot="prepend"
              ><i class="fa fa-credit-card px-3"></i
            ></b-input-group-text>
            <b-form-input
              type="text"
              name="number"
              id="number"
              v-validate="'required'"
              :state="validateState('number')"
              v-model="form.number"
              :data-vv-as="$t('cardNumber')"
            ></b-form-input>
          </b-input-group>
          <span class="error-inputs">{{ errors.first("number") }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-8">
            <label for="month">{{ $t("expirationDate") }}</label>
            <div class="row">
              <div class="col-6">
                <b-form-input
                  type="text"
                  name="month"
                  id="month"
                  v-validate="'required'"
                  :state="validateState('month')"
                  v-model="form.month"
                  :data-vv-as="$tc('month', 1)"
                  placeholder="MM"
                ></b-form-input>
                <span class="error-inputs">{{ errors.first("month") }}</span>
              </div>
              <div class="col-6">
                <b-form-input
                  type="text"
                  name="year"
                  id="year"
                  :data-vv-as="$tc('year', 1)"
                  v-validate="'required'"
                  :state="validateState('year')"
                  v-model="form.year"
                  placeholder="YYYY"
                ></b-form-input>
                <span class="error-inputs">{{ errors.first("year") }}</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <label for="cvv">CVV</label>
            <b-form-input
              type="password"
              name="cvv"
              id="cvv"
              v-validate="'required'"
              :state="validateState('cvv')"
              v-model="form.cvv"
              placeholder="***"
            ></b-form-input>
            <span class="error-inputs">{{ errors.first("cvv") }}</span>
          </div>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <b-button type="button" @click="onSubmit" variant="primary">{{
        $t("save")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    nick: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        price: "2.20",
      },
      title: "",
    };
  },
  computed: {
    ...mapGetters(["loading", "lang"]),
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.form.username = this.user.username;
          const console = this.$route.params.console;
          this.$axios
            .post(`auth/${this.lang}/${console}/user/change-nick`, this.form)
            .then((response) => {
              this.$emit("onUpdateNick", this.form.nick);
              this.$toastr.success(response.data.message, window.TITLE_SUCCESS);
              this.$root.$emit("bv::hide::modal", "modalChangePsn");
            });
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      });
    },
    onShow() {
      console.log("melas");
      this.title = `${this.$t("change")} ${this.nick}`;
      this.form.nick = this.user.nick;
      this.form.number = "";
      this.form.month = "";
      this.form.year = "";
      this.form.cvv = "";
      this.$forceUpdate();
    },
  },
};
</script>
