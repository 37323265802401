<template>
  <div>
    <b-form class="row" @submit.prevent="save">
      <div class="col-12">
        <h5>{{ $t("editUser") }}</h5>
        <hr />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="username">{{ $t("username") }}</label>
        <b-form-input
          name="username"
          id="username"
          v-model="user.username"
          readonly
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="country">{{ $t("country") }}</label>
        <b-form-input
          name="country"
          id="country"
          v-model="user.country"
          readonly
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="lang">{{ $t("language") }}</label>
        <multiselect
          v-validate="'required'"
          :state="validateState('lang')"
          name="lang"
          id="lang"
          v-model="user.lang"
          :options="langs"
          trackBy="value"
          label="text"
          deselectLabel=""
          :placeholder="$t('selectOption')"
          :searchable="false"
          selectLabel=""
          selectedLabel=""
        >
        </multiselect>
        <span class="error-inputs">{{ errors.first("lang") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="position">{{ $t("position") }}</label>
        <multiselect
          v-validate="'required'"
          :state="validateState('position')"
          name="position"
          id="position"
          v-model="user.position"
          :options="availablePositions"
          deselectLabel=""
          track-by="value"
          label="text"
          :placeholder="$t('selectOption')"
          :allow-empty="false"
          selectLabel=""
          selectedLabel=""
        >
          <template slot="singleLabel" slot-scope="{ option }">{{
            option.text
          }}</template>
        </multiselect>
        <span class="error-inputs">{{ errors.first("position") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="chem_style">Chem style</label>
        <multiselect
          v-validate="'required'"
          :state="validateState('chem_style')"
          name="chem_style"
          id="chem_style"
          v-model="user.chem_style"
          :options="chemStyles"
          deselectLabel=""
          :placeholder="$t('selectOption')"
          selectLabel=""
          selectedLabel=""
          trackBy="value"
          label="text"
          data-vv-as="chem style"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{
            option.text
          }}</template>
        </multiselect>
        <span class="error-inputs">{{ errors.first("chem_style") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="console">{{ $t("console") }}</label>
        <multiselect
          v-validate="'required'"
          :state="validateState('console')"
          name="console"
          id="console"
          v-model="user.console"
          :options="consoles"
          deselectLabel=""
          :placeholder="$t('selectOption')"
          :searchable="false"
          selectLabel=""
          selectedLabel=""
          @select="changeNick"
        >
        </multiselect>
        <span class="error-inputs">{{ errors.first("console") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <div class="d-flex justify-content-between align-items-center">
          <label for="psnId">{{ nick }}</label>
          <a
            @click.prevent="$root.$emit('bv::show::modal', 'modalChangePsn')"
            href="#"
            >{{ $t("change") }}</a
          >
        </div>
        <b-form-input
          name="psnId"
          id="psnId"
          v-model="user.nick"
          :state="validateState('psnId')"
          data-vv-as="nick"
          readonly
        />
        <span class="error-inputs">{{ errors.first("psnId") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="facebook">Facebook</label>
        <b-form-input
          name="facebook"
          id="facebook"
          v-validate="'max:190'"
          v-model="user.facebook"
          :state="validateState('facebook')"
        />
        <span class="error-inputs">{{ errors.first("facebook") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="motto">{{ $t("motto") }}</label>
        <b-form-input
          name="motto"
          id="motto"
          v-validate="'required|max:50'"
          v-model="user.motto"
          :state="validateState('motto')"
        />
        <span class="error-inputs">{{ errors.first("motto") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="overall">Overall</label>
        <b-form-input
          type="number"
          name="overall"
          id="overall"
          v-validate="'required|min_value:79|max_value:99'"
          v-model="user.overall"
          :state="validateState('overall')"
          data-vv-as="overall"
        />
        <span class="error-inputs">{{ errors.first("overall") }}</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="email">{{ $t("email") }}</label>
        <b-form-input
          name="email"
          type="email"
          id="email"
          v-validate="'required|email'"
          v-model="user.email"
          :state="validateState('email')"
        />
        <span class="error-inputs">{{ errors.first("email") }}</span>
      </div>
      <div class="col-12">
        <b-button type="submit" :disabled="loading" variant="primary">{{
          $t("save")
        }}</b-button>
      </div>
    </b-form>
    <ModalChangePsn
      :user="user"
      :nick="nick"
      :show.sync="showModalChangePsn"
      @onUpdateNick="onUpdateNick"
    ></ModalChangePsn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalChangePsn from "./ModalChangePsn";

export default {
  components: {
    ModalChangePsn,
  },
  data() {
    return {
      user: {},
      availablePositions: [],
      consoles: [],
      chemStyles: [],
      nick: "",
      langs: [],
      showModalChangePsn: false,
    };
  },
  computed: {
    ...mapGetters(["loading", "lang", "countries"]),
  },
  mounted() {
    document.title = `${this.$t("editUser")} - ITFA`;
    this.availablePositions = window.positions;
    this.consoles = window.consoles;
    this.chemStyles = window.chemStyles;
    this.langs = window.langs;
    this.fetchUser();
  },
  methods: {
    onUpdateNick(nick) {
      this.user.nick = nick;
    },
    changeNick(val) {
      this.setNick(val);
    },
    setNick(console) {
      if (console === "PSN") {
        this.nick = "Psn id";
      }
      if (console === "XBOX") {
        this.nick = "Gamer tag";
      }
      if (console === "PC") {
        this.nick = "Origin";
      }
    },
    fetchUser() {
      const console = this.$route.params.console;
      const path = `auth/${this.lang}/${console}/user/information`;
      this.$axios.get(path).then((response) => {
        const data = response.data.data;
        this.user = data;
        this.user.position = window.positions
          .filter((position) => {
            return position.value === data.position;
          })
          .reduce((position) => {
            return position;
          });
        this.user.chem_style = window.chemStyles
          .filter((chem) => {
            return chem.value === this.user.chem_style;
          })
          .reduce((chem) => {
            return chem;
          });
        this.user.lang = this.langs
          .filter((l) => l.value === this.user.lang)
          .reduce((l) => l);
        this.setNick(this.user.console);
      });
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          const payload = {
            console: this.user.console,
            position: this.user.position.value,
            motto: this.user.motto,
            email: this.user.email,
            lang: this.user.lang.value,
            chem_style: this.user.chem_style.value,
            facebook: this.user.facebook,
            overall: this.user.overall,
          };
          const console = this.$route.params.console;
          const path = `auth/${this.lang}/${console}/user/update`;
          this.$axios.post(path, payload).then((response) => {
            this.changeLanguage(this.user.lang.value);
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS);
          });
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      });
    },
  },
};
</script>
